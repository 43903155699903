<script setup lang="ts">
import { SubscriptionType } from '@respell/database';
import { subscriptionDetails } from '@respell/utils';

const { plan } = defineProps({
  plan: {
    type: String,
    required: true,
  },
});

const modal = useModal();
</script>

<template>
  <UModal>
    <UCard
      class="w-full"
      :ui="{
        ring: '',
        divide: '',
      }"
    >
      <div class="flex flex-col items-center gap-4">
        <UIcon
          name="i-ph-confetti"
          size="xl"
          class="text-5xl text-primary-500"
        />
        <p class="main-title">Upgrade Successful!</p>
        <p class="dimmed text-center">
          Congratulations on stepping up to the
          {{ subscriptionDetails[plan as SubscriptionType]?.shortName }} plan!
        </p>
        <div class="mt-4">
          <UButton size="xl" @click="modal.close">Get Started</UButton>
        </div>
      </div>
    </UCard>
  </UModal>
</template>
