<script setup lang="ts">
import confetti from 'canvas-confetti';
import { formatDistanceToNow } from 'date-fns';
import AppSpellCarousel from '~/components/app/AppSpellCarousel.vue';
import InsightsChart from '~/components/chart/InsightsChart.vue';
import HistoryTable from '~/components/history/HistoryTable.vue';
import QuestionnaireModal from '~/components/modals/QuestionnaireModal.vue';
import UpgradeSuccessModal from '~/components/modals/UpgradeSuccessModal.vue';

const spellStore = useSpellsStore();
const userStore = useUserStore();
const tutorialStore = useTutorialStore();

const { createSpell } = spellStore;
const { profile } = storeToRefs(userStore);
const { restartTutorial } = tutorialStore;

const { data: suggestedTemplates } = await useAsyncCache(
  'suggestedTemplates',
  () => spellStore.loadSuggested(),
  { lazy: true },
);

const { data: unfinishedSpells } = await useAsyncData(
  `unfinishedSpells`,
  () => spellStore.loadUnfinished(),
  { lazy: true },
);

const modal = useModal();
const router = useRouter();

const searchItem = ref('');
const searching = ref(false);

const query = computed(() => router.currentRoute.value.query);

onMounted(async () => {
  if (query.value.plan) {
    confetti({
      spread: 180,
      startVelocity: 80,
      particleCount: 100,
      origin: { x: 0.5, y: 1 },
      gravity: 0.5,
      ticks: 100,
      scalar: 2,
    });
    modal.open(UpgradeSuccessModal, { plan: String(query.value.plan) });

    await refreshNuxtData('workspaces');
  }
  setTimeout(() => {
    if (!profile.value?.milestones?.includes('questionnaire')) {
      modal.open(QuestionnaireModal);
    }
  }, 1500);
});

const startChat = () => {
  window.$unthread.inAppChat('toggle', 'show');
};

const quickLinks = [
  {
    icon: 'i-ph-hand-waving-bold',
    label: 'Try tutorial',
    click: restartTutorial,
  },
  {
    icon: 'i-ph-play-circle-bold',
    label: 'Watch demo',
    external: true,
    to: 'https://www.youtube.com/watch?v=BkRMuRtMZMw&list=PLs1LcDIFTUu3S-CYPjCZXBSxnzbvK_YpI&index=11',
  },
  {
    icon: 'i-ph-slack-logo-bold',
    label: 'Join Slack',
    external: true,
    to: 'https://join.slack.com/t/respellcommunity/shared_invite/zt-2hu3jrvs7-OOIprhyCGIeOB8hiy~AmOg',
  },
  {
    icon: 'i-ph-chats-teardrop-bold',
    label: 'Chat with us',
    click: startChat,
  },
];

const spellGrids = computed(() => {
  const baseGrids = [];

  if (unfinishedSpells.value?.length) {
    baseGrids.push({
      icon: 'i-ph-hammer',
      key: 'unfinished',
      title: 'Recently edited',
      spells: unfinishedSpells.value,
    });
  }

  if (suggestedTemplates.value?.length) {
    baseGrids.push({
      icon: 'i-ph-sparkle',
      key: 'for-you',
      title: 'Templates for you',
      spells: suggestedTemplates.value,
    });
  }

  return baseGrids;
});

async function searchSpells(q: string) {
  searching.value = true;

  const results = await $api<Spell[]>('api/spells/search', {
    params: { q },
  });

  searching.value = false;

  return results;
}

function handleSelect(newValue: string) {
  if (newValue) {
    return navigateTo({ name: 'spell', params: { spellId: newValue.id } });
  }
}
</script>

<template>
  <NuxtLayout name="dashboard">
    <div class="flex flex-col grow gap-10">
      <div class="w-full flex gap-10">
        <UInputMenu
          v-model="searchItem"
          :search="searchSpells"
          :loading="searching"
          :trailing="false"
          placeholder="Search your spells..."
          option-attribute="name"
          leading-icon="i-ph-magnifying-glass-bold"
          size="xl"
          class="grow"
          by="id"
          :ui="{
            icon: {
              trailing: {
                wrapper: 'opacity-0 cursor-text',
              },
            },
          }"
          :ui-menu="{
            option: {
              base: 'cursor-pointer py-2',
            },
          }"
          @update:model-value="(newValue) => handleSelect(newValue)"
        >
          <template #option-empty="{ query }">
            <p v-if="searching" class="text-gray-500">Searching...</p>
            <p v-else class="text-gray-500">
              No matching spells found for "{{ query }}"
            </p>
          </template>
          <template #empty>Start typing to search</template>
          <template #option="{ option: spell }">
            <AppSpellIcon :spell="spell" size="m" />
            <span class="truncate">{{ spell.name }}</span>
            <span class="truncate caption"
              >Edited {{ formatDistanceToNow(spell.updatedAt) }} ago
            </span>
          </template>
        </UInputMenu>
      </div>
      <!-- HEADER -->
      <div class="flex w-full gap-4 items-start justify-between h-32">
        <!-- WELCOME BANNER -->
        <div
          class="flex justify-between p-6 w-full h-full rounded-lg bg-primary-50 border border-primary-200"
        >
          <span class="flex flex-col items-start gap-4">
            <p class="main-title" data-testid="welcome-header">
              Welcome back,
              {{ profile?.fullName?.split(' ')[0] }}
            </p>

            <p class="body dimmed">
              Pick up where you left off or start building something new.
            </p>
          </span>
          <UButton
            size="xl"
            color="primary"
            variant="solid"
            label="New Spell"
            icon="i-ph-plus"
            :ui="{
              rounded: 'rounded-full',
              font: 'font-bold',
              padding: { xl: 'px-6 py-3' },
            }"
            @click="createSpell"
          />
        </div>

        <!-- QUICK LINKS -->
        <div
          class="xl:flex flex-col items-start h-full justify-between p-2 rounded-lg border-container hidden w-fit shrink-0"
        >
          <p class="subtitle pl-3">Quick links</p>
          <div class="grid grid-cols-2">
            <UButton
              v-for="link in quickLinks"
              :key="link.label"
              :to="link.to"
              :click="link.click"
              :external="link.external"
              size="lg"
              :label="link.label"
              :icon="link.icon"
              variant="link"
              color="gray"
              :ui="{ rounded: 'rounded-lg' }"
              @click="link.click"
            />
          </div>
        </div>
      </div>

      <!-- HISTORY -->
      <div class="grid lg:grid-cols-2 gap-8 w-full items-start lg:h-80">
        <div class="flex flex-col items-start justify-start gap-4 h-full">
          <span class="flex items-center gap-2 title">
            <UIcon
              name="i-ph-clock-counter-clockwise-bold"
              class="text-2xl text-gray-500"
            />
            Recent runs
          </span>

          <HistoryTable
            :fields="['name', 'status', 'startedAt', 'result']"
            :page-size="4"
            hide-pagination
            from-now
            class="w-full"
          />
        </div>

        <!-- INSIGHTS -->
        <div class="flex flex-col items-start justify-start h-full gap-4">
          <span class="flex items-center gap-2 title">
            <UIcon name="i-ph-lightbulb" class="text-2xl text-gray-500" />
            Insights
          </span>

          <InsightsChart size="sm" class="w-full" />
        </div>
      </div>

      <!-- Spell Carousels -->
      <section class="flex flex-col w-full gap-6 max-w-full">
        <div
          v-for="grid in spellGrids"
          :key="grid.key"
          class="flex flex-col gap-4 max-w-full w-full"
        >
          <header class="flex w-full justify-between items-end">
            <h2 class="flex gap-2 title">
              <UIcon :name="grid.icon" class="text-2xl text-gray-500" />
              {{ grid.title }}
            </h2>
          </header>
          <AppSpellCarousel
            :spells="grid.spells"
            :templated="grid.key === 'for-you'"
            class="w-full"
          />
        </div>
      </section>
    </div>
  </NuxtLayout>
</template>
